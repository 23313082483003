import {
  Link, Typography, List, ListItem,
  ListItemAvatar, ListItemText, Divider,
  Box, Grid, Alert, Stack,
} from '@mui/material';
import { useCallback, VFC } from 'react';
import {
  useAddReviewMutation,
  useGetReviewsQuery,
} from '../../../../plugins/gatsby-plugin-redux/store/api/book-details.api';
import useAuth from '../../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import useIsLoggedIn from '../../../../plugins/gatsby-plugin-session-check/hooks/useIsLoggedIn';
import Form from '../../../field/Form';
import InputField from '../../../field/InputField';
import RatingField from '../../../field/RatingField';
import dateWithoutYear from '../../../helpers/dateWithoutYear';
import handleSubmitAction from '../../../helpers/handleSubmitAction';
import { isNullable, isNumber, isRequired } from '../../../helpers/validators';
import { SubmitHandler } from '../../../types/app';
import { AddReviewFormData } from '../../../types/form';
import { BookSchema } from '../../../types/schema';
import CircularProgress from '../../app/CircularProgress';
import ErrorAlert from '../../app/ErrorAlert';
import HtmlForm from '../../app/HtmlForm';
import LoadingButton from '../../app/LoadingButton';
import Rating from '../../app/Rating';
import UserAvatar from '../../app/UserAvatar';
import SectionTitle from './SectionTitle';

type ReviewsProps = {
  slug: string;
  book: BookSchema;
}

const Reviews: VFC<ReviewsProps> = ({ slug, book }) => {
  const auth = useAuth();
  const isLoggedIn = useIsLoggedIn();
  const { data: reviews = [], isLoading: isLoadingReviews } = useGetReviewsQuery(slug);
  const [addReviewAction] = useAddReviewMutation();

  const handleAddReview = useCallback<SubmitHandler<AddReviewFormData>>(async (review, helpers) => {
    await addReviewAction({
      ...review,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...auth!,
    }).unwrap();

    helpers.resetForm();
  }, [addReviewAction, auth]);

  return (
    <Box component="section">
      <SectionTitle>Reviews</SectionTitle>
      {isLoggedIn && (
      <Form<AddReviewFormData>
        onSubmit={handleSubmitAction(handleAddReview)}
        initialValues={{
          comment: '',
          rating: 0,
          slug,
        }}
      >
        {(config) => (
          <HtmlForm onSubmit={config.handleSubmit}>
            <ErrorAlert message={config.errors._server?.message} />
            <Grid container columnSpacing={2} alignItems="flex-start" marginTop={3}>
              <Grid item>
                <UserAvatar src={auth?.avatar} />
              </Grid>
              <Grid item xs md={6}>
                <InputField
                  placeholder="What's your thoughts on this book?"
                  multiline
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="comment"
                  rules={[isRequired]}
                />
                <Grid container columnSpacing={3} marginTop={2}>
                  <Grid item>
                    <LoadingButton
                      loading={config.isLoading}
                      size="small"
                      variant="contained"
                      sx={{ borderRadius: 7 }}
                      type="submit"
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                  {!book.hasReviewed && (
                  <Grid item>
                    <RatingField name="rating" rules={[isNullable, isNumber]} />
                  </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </HtmlForm>
        )}
      </Form>
      )}

      <Box marginY={3}>
        {isLoadingReviews && !reviews.length && <CircularProgress center />}
        {!isLoadingReviews && !reviews.length && <Alert severity="info">No reviews. Be the first to add a review.</Alert>}

        {!!reviews.length && (
        <List sx={{ width: 600, maxWidth: '100%' }}>
          {reviews?.map((review, index) => (
            <>
              <ListItem key={review.id} disableGutters alignItems="flex-start">
                <ListItemAvatar>
                  <UserAvatar showDefault={false} name={review.fullName} src={review.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={(
                    <>
                      <Typography
                        variant="h6"
                        component={Link}
                        href={`/${review.username}`}
                        sx={{ fontWeight: 'normal' }}
                      >
                        {review.fullName}
                      </Typography>
                      <Stack columnGap={2} flexDirection="row" display="flex" alignItems="center" marginBottom={1}>
                        {review.rating !== 0 && <Rating readOnly value={review.rating} size="small" />}
                        <Typography variant="body2">{dateWithoutYear(review.postedAt)}</Typography>
                      </Stack>
                    </>
                    )}
                  secondary={(
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      color="text.primary"
                    >
                      {review.comment}
                    </Typography>
                    )}
                />
              </ListItem>
              {index !== reviews.length - 1 && <Divider key={`${review.id}${review.comment}`} variant="inset" component="li" />}
            </>
          ))}
        </List>
        )}
      </Box>
    </Box>
  );
};

export default Reviews;
