import {
  Backdrop, Box, CircularProgress,
  DialogActions, DialogContent,
  InputAdornment, Typography,
} from '@mui/material';
import { useCallback, VFC } from 'react';
import { useFundWalletMutation } from '../../../../plugins/gatsby-plugin-redux/store/api/wallet.api';
import useAuth from '../../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import Form from '../../../field/Form';
import InputField from '../../../field/InputField';
import handleSubmitAction from '../../../helpers/handleSubmitAction';
import { isRequired } from '../../../helpers/validators';
import useFlutterwave from '../../../hooks/useFlutterwave';
import { SubmitHandler } from '../../../types/app';
import { FundWalletFormData } from '../../../types/form';
import Dialog from '../../app/Dialog';
import DialogTitle from '../../app/DialogTitle';
import ErrorAlert from '../../app/ErrorAlert';
import LoadingButton from '../../app/LoadingButton';

type FundWalletFlowProps = {
  isOpen: boolean;
  onComplete: () => void;
  onClose?: () => void;
  defaultAmount?: number;
}

const FundWalletFlow: VFC<FundWalletFlowProps> = ({
  isOpen,
  onComplete,
  onClose,
  defaultAmount,
}) => {
  const auth = useAuth();
  const [fundWalletAction, { isLoading }] = useFundWalletMutation();
  const [initFlutterwave, { isVerifying }] = useFlutterwave();

  const handleSubmit = useCallback<SubmitHandler<FundWalletFormData>>(async (values) => {
    const result = await fundWalletAction({ amount: values.amount }).unwrap();

    initFlutterwave({
      amount: values.amount,
      reference: result.reference,
      onClose,
      onSuccess: () => {
        onComplete();
        onClose?.();
      },
    });
  }, [fundWalletAction, initFlutterwave, onClose, onComplete]);

  if (isVerifying) {
    return (
      <Backdrop
        open
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 50,
          flexDirection: 'column',
        }}
      >
        <CircularProgress color="inherit" />
        <Typography marginTop={2}>Your payment is being verified...</Typography>
      </Backdrop>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth persistent={isLoading}>
      <DialogTitle>Fund wallet</DialogTitle>
      <Form<FundWalletFormData>
        initialValues={{ amount: defaultAmount?.toString() || '' }}
        onSubmit={handleSubmitAction(handleSubmit)}
      >
        {(config) => (
          <>
            <DialogContent>
              <ErrorAlert message={config.errors._server?.message} />
              <Box>
                <InputField
                  fullWidth
                  name="amount"
                  label="Amount"
                  placeholder="0.00"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{auth?.currencyCode}</InputAdornment>,
                  }}
                  rules={[isRequired]}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                loading={isLoading}
                onClick={config.handleSubmit}
                disableElevation
              >
                Fund wallet
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default FundWalletFlow;
