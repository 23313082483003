import dayjs from 'dayjs';

const dateWithoutYear = (date: string) => {
  const currentYear = dayjs().format('Y');
  const dateFormatted = dayjs(date).format('D MMM, Y');
  const parts = dateFormatted.split(', ');

  return currentYear === parts.pop() ? parts.shift() : dateFormatted;
};

export default dateWithoutYear;
