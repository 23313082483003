import {
  FormHelperText, Rating as BaseRating, RatingProps as BaseRatingProps, Stack,
} from '@mui/material';
import { VoidFunctionComponent } from 'react';
import { BaseComponentFieldProps } from '../../types/app';

export type RatingProps = BaseRatingProps & Pick<BaseComponentFieldProps, 'error' | 'helperText'>;

const Rating: VoidFunctionComponent<RatingProps> = ({
  value,
  helperText,
  error,
  ...props
}) => (
  <Stack alignItems="center">
    <BaseRating precision={0.5} {...props} value={Number(value)} />

    {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </Stack>
);

export default Rating;
