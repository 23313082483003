import { AddShippingInfoFormData, AddVoucherCodeFormData, PayWalletFormData } from '../../../../src/types/form';
import { ShippingRateSchema, VerifyVoucherSchema } from '../../../../src/types/schema';
import { CanBuySchema } from '../../../../src/types/schema/ checkout';
import { addShippingFee, addVoucher } from '../checkout.slice';
import { publicationApi } from './dashboard/publication.api';

const checkoutApi = publicationApi.injectEndpoints({
  endpoints: (builder) => ({
    payWallet: builder.mutation<{ reference: string;}, PayWalletFormData>({
      invalidatesTags: ['Auth'],
      query: (arg) => ({ url: 'dashboard/wallets/pay', method: 'POST', body: arg }),
    }),
    verifyVoucher: builder.mutation<VerifyVoucherSchema, AddVoucherCodeFormData>({
      query: (arg) => ({ url: 'dashboard/vouchers/verify', method: 'POST', body: arg }),
      onCacheEntryAdded: async (formData, { dispatch, getCacheEntry, cacheDataLoaded }) => {
        await cacheDataLoaded;
        const cache = getCacheEntry();

        dispatch(addVoucher({ code: formData.code, price: Number(cache.data?.price) }));
      },
    }),
    calculateShippingInfo: builder.mutation<
      ShippingRateSchema,
      AddShippingInfoFormData & { slug: string }
    >({
      query: (formData) => ({ url: 'dashboard/shipping/fees', method: 'POST', body: formData }),
      onCacheEntryAdded: async (_, { dispatch, getCacheEntry, cacheDataLoaded }) => {
        await cacheDataLoaded;
        const cache = getCacheEntry();

        dispatch(addShippingFee(Number(cache.data?.amount)));
      },
    }),
    canBuy: builder.mutation<CanBuySchema, PayWalletFormData>({
      query: (formData) => ({ url: 'dashboard/wallets/can-buy', method: 'POST', body: formData }),
    }),
  }),
});

export const {
  usePayWalletMutation,
  useVerifyVoucherMutation,
  useCalculateShippingInfoMutation,
  useCanBuyMutation,
} = checkoutApi;
