import {
  List, ListItemButton,
  ListItemIcon, ListItemText,
  ListSubheader,
  SwipeableDrawer,
} from '@mui/material';
import {
  FacebookMessenger,
  Facebook, Twitter,
  ContentCopy, Whatsapp,
  Linkedin,
} from 'mdi-material-ui';
import {
  FC,
  ReactNode, useCallback, useMemo, useState,
} from 'react';
import copyToClipboard from '../../helpers/copyToClipboard';
import { trackShare } from '../../helpers/gtag';

type ChildrenProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => Promise<void>;
}

type SharerProps = {
  url: string;
  title: string;
  children: (options: ChildrenProps) => ReactNode;
}

const Sharer: FC<SharerProps> = ({
  url,
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeSharer = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOpen = async () => {
    if (navigator.share) {
      await navigator.share({
        title,
        url,
      });
    } else {
      setIsOpen(true);
    }
  };

  const openWindow = useCallback((link: string) => {
    closeSharer();
    const left = (window.screen.width - 570) / 2;
    const top = (window.screen.height - 570) / 2;
    const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;
    window.open(link, 'NewWindow', params);
  }, [closeSharer]);

  const newsFeedHandler = useCallback(() => {
    window.FB.ui({
      method: 'feed',
      link: url,
      redirect_url: url,
    });
    closeSharer();
  }, [closeSharer, url]);

  const messengerHandler = useCallback(() => window.FB.ui({
    method: 'send',
    link: url,
    redirect_url: url,
  }), [url]);

  const twitterHandler = useCallback(() => openWindow(
    `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
  ), [openWindow, url, title]);

  const linkedinHandler = useCallback(() => openWindow(
    `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
  ), [openWindow, url]);

  const clipboardHandler = useCallback(async () => {
    if (!navigator.clipboard) {
      copyToClipboard(url);
    } else {
      await navigator.clipboard.writeText(url);
    }
    closeSharer();
  }, [closeSharer, url]);

  const whatsappHandler = useCallback(() => (
    openWindow(`whatsapp://send?text=${url}`)
  ), [openWindow, url]);

  const tiles = useMemo(() => [
    {
      icon: Facebook,
      title: 'Facebook',
      handler: newsFeedHandler,
      hidden: true,
    },
    {
      icon: Twitter,
      title: 'Twitter',
      handler: twitterHandler,
    },
    {
      icon: FacebookMessenger,
      title: 'Messenger',
      handler: messengerHandler,
      hidden: true,
    },
    {
      icon: Linkedin,
      title: 'Linkedin',
      handler: linkedinHandler,
    },
    {
      icon: Whatsapp,
      title: 'Whatsapp',
      handler: whatsappHandler,
    },
    {
      icon: ContentCopy,
      title: 'Copy to Clipboard',
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      handler: clipboardHandler,
    },
  ], [
    clipboardHandler,
    linkedinHandler,
    messengerHandler,
    newsFeedHandler,
    twitterHandler,
    whatsappHandler,
  ]);

  const handleClick = useCallback((tile: typeof tiles[0]) => () => {
    trackShare(tile.title, 'book', url);

    tile.handler();
  }, [url]);

  return (
    <>
      {children({ isOpen, onClose: closeSharer, onOpen })}
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onOpen={onOpen}
        onClose={closeSharer}
        sx={{ paddingX: { md: 20, xs: 10 } }}
      >
        <List>
          <ListSubheader>Share</ListSubheader>
          {tiles.filter((tile) => !tile.hidden).map((tile) => (
            <ListItemButton onClick={handleClick(tile)} key={tile.title}>
              <ListItemIcon>
                <tile.icon />
              </ListItemIcon>
              <ListItemText primary={tile.title} />
            </ListItemButton>
          ))}
        </List>
      </SwipeableDrawer>
    </>
  );
};

export default Sharer;
