import { api } from '../index';
import {
  ServerResponse, CollaboratorType,
  CurrencyCodeType, PricingSchemaType,
  PricingType, MediaType,
} from '../../../../../src/types/app';
import {
  CreatePublicationFormData, EditBasicInfoFormData, AddCollaboratorFormData, AddPricingFormData,
} from '../../../../../src/types/form';
import { BookInfoSchema, BookSchema, PublishStatusSchema } from '../../../../../src/types/schema';

export const publicationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createPublication: builder.mutation<BookInfoSchema, CreatePublicationFormData>({
      query: (arg) => ({ url: 'dashboard/publications', method: 'POST', body: arg }),
      transformResponse: (response: ServerResponse<BookSchema>) => response.data,
    }),
    getPublication: builder.query<BookSchema, string>({
      providesTags: ['Book'],
      query: (slug) => `dashboard/publications/${slug}`,
      transformResponse: (
        response: ServerResponse<BookSchema> & {file: string;},
      ) => ({ ...response.data, file: response.file }),
    }),
    getPublications: builder.query<Pick<BookSchema, 'title' | 'rating' | 'slug' | 'updatedAt'| 'type'| 'pricingType'>[], void>({
      providesTags: ['Book'],
      query: () => 'dashboard/publications',
      transformResponse: (
        response: ServerResponse<Pick<BookSchema, 'title' | 'rating' | 'slug' | 'updatedAt'| 'type'| 'pricingType'>[]>,
      ) => response.data,
    }),
    saveBasicInfo: builder.mutation<BookSchema, EditBasicInfoFormData & { slug: string; }>({
      invalidatesTags: ['PublishStatus'],
      query: ({ slug, ...arg }) => ({ url: `dashboard/publications/${slug}/info`, method: 'PATCH', body: arg }),
      transformResponse: (response: ServerResponse<BookSchema>) => response.data,
    }),
    getCollaborators: builder.query<CollaboratorType[], void>({
      query: () => 'dashboard/collaborators',
      transformResponse: (response: ServerResponse<CollaboratorType[]>) => response.data,
    }),
    addCollaborator: builder.mutation<
      CollaboratorType,
      AddCollaboratorFormData & { slug: string }
      >({
        invalidatesTags: ['PublishStatus'],
        query: ({ slug, ...formData }) => ({ url: `dashboard/publications/${slug}/collaborators`, method: 'POST', body: formData }),
        onQueryStarted: async ({ slug, ...formData }, { dispatch, queryFulfilled }) => {
          const patchPublishStatus = dispatch(
            publicationApi.util.updateQueryData('getPublishStatus', slug, (draft) => {
              if (!draft.collaborators && formData.type === 'author') {
                draft.collaborators = true;
              }
            }),
          );

          try {
            const result = await queryFulfilled.catch(patchPublishStatus.undo);

            dispatch(
              publicationApi.util.updateQueryData('getPublication', slug, (draft) => {
                const { data } = result?.data as unknown as ServerResponse<CollaboratorType>;
                if (draft.collaborators.findIndex((item) => item.email === data.email) === -1) {
                  draft.collaborators.unshift(data);
                }
              }),
            );
          } catch {
            //
          }
        },
      }),
    deleteCollaborator: builder.mutation<void, { slug: string; collaboratorIds: string[]; }>({
      invalidatesTags: ['PublishStatus'],
      query: ({ slug, collaboratorIds }) => ({
        url: `dashboard/publications/${slug}/collaborators`,
        method: 'DELETE',
        body: { ids: collaboratorIds },
      }),
      async onQueryStarted({ collaboratorIds, slug }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          publicationApi.util.updateQueryData('getPublication', slug, (draft) => {
            draft.collaborators = draft.collaborators.filter((collaborator) => (
              !collaboratorIds.includes(collaborator.id)
            ));
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    addPricing: builder.mutation<void, AddPricingFormData & { slug: string; }>({
      invalidatesTags: ['PublishStatus'],
      query: ({ slug, id, ...formData }) => ({
        url: `dashboard/publications/${slug}/pricings`,
        method: !id ? 'POST' : 'PATCH',
        body: { ...formData, id },
      }),
      onQueryStarted: async ({
        slug, id, amount, currencyCode,
      }, { dispatch, queryFulfilled }) => {
        if (id) {
          const patches = dispatch(
            publicationApi.util.updateQueryData('getPublication', slug, (draft) => {
              const index = draft.pricings.findIndex((item) => item.currencyCode === currencyCode);

              if (index === -1) {
                return;
              }

              draft.pricings[index].amount = Number(amount);
              draft.pricings[index].currencyCode = currencyCode as CurrencyCodeType;
            }),
          );
          await queryFulfilled.catch(patches.undo);
          return;
        }

        try {
          const result = await queryFulfilled;

          dispatch(
            publicationApi.util.updateQueryData('getPublication', slug, (draft) => {
              const { data } = result.data as unknown as ServerResponse<PricingSchemaType[]>;
              draft.pricings = data;
              draft.pricingType = 'paid';
            }),
          );
        } catch {
          //
        }
      },
    }),
    updatePricingType: builder.mutation<void, { slug: string; pricingType: PricingType; }>({
      query: ({ slug, pricingType }) => ({
        url: `dashboard/publications/${slug}/pricings/type`,
        method: 'PATCH',
        body: { pricingType },
      }),
      onQueryStarted: async ({ slug, pricingType }, { dispatch, queryFulfilled }) => {
        const patches = dispatch(
          publicationApi.util.updateQueryData('getPublication', slug, (draft) => {
            if (draft.type !== 'print') {
              draft.pricingType = pricingType;

              if (pricingType === 'free') {
                draft.pricings = [];
              }
            }
          }),
        );

        await queryFulfilled.catch(patches.undo);
      },
    }),
    getPublishStatus: builder.query<PublishStatusSchema, string>({
      providesTags: ['PublishStatus'],
      query: (slug) => `dashboard/publications/${slug}/status`,
    }),
    publishPublication: builder.mutation<void, string>({
      query: (slug) => ({
        url: `dashboard/publications/${slug}/status`,
        method: 'PATCH',
      }),
      onQueryStarted: async (slug, { dispatch, queryFulfilled }) => {
        const patches = dispatch(publicationApi.util.updateQueryData('getPublication', slug, (draft) => {
          draft.status = 'published';
        }));

        await queryFulfilled.catch(patches.undo);
      },
    }),
    saveUploadedFile: builder.mutation<
      { message: string; file: string; },
      { slug: string; fileId: string; type: MediaType }
    >({
      query: (args) => ({ url: 'dashboard/media', body: args, method: 'POST' }),
      onCacheEntryAdded: async (args, { dispatch, getCacheEntry, cacheDataLoaded }) => {
        await cacheDataLoaded;

        const cache = getCacheEntry();

        if (cache.isSuccess && cache.data?.file) {
          dispatch(
            publicationApi.util.updateQueryData('getPublication', args.slug, (draft) => {
              draft.file = new URL(cache.data?.file).pathname;
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useAddCollaboratorMutation,
  useAddPricingMutation,
  useDeleteCollaboratorMutation,
  useGetCollaboratorsQuery,
  useGetPublicationQuery,
  useGetPublishStatusQuery,
  usePublishPublicationMutation,
  useUpdatePricingTypeMutation,
  useSaveBasicInfoMutation,
  useSaveUploadedFileMutation,
  useCreatePublicationMutation,
  useGetPublicationsQuery,
} = publicationApi;
