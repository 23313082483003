import { VFC } from 'react';
import Rating, { RatingProps } from '../components/app/Rating';
import FieldControl, { FieldProps } from './FieldControl';

type RatingFieldProps = FieldProps & RatingProps;

const RatingField: VFC<RatingFieldProps> = (props) => (
  <FieldControl component={Rating} {...props} />
);

export default RatingField;
