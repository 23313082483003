import { useCallback } from 'react';
import useAppDispatch from '../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { useVerifyTransactionMutation } from '../../plugins/gatsby-plugin-redux/store/api/wallet.api';
import useAuth from '../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import { addSnackbar } from '../../plugins/gatsby-plugin-snackbar/store/snackbar';
import { ErrorType, getErrorMessage } from '../helpers/handleSubmitAction';

type InitFlutterwaveArgs = {
  amount: string | number;
  reference: string;
  onClose?: () => void;
  onSuccess?: () => void;
};

const useFlutterwave = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [
    verifyTransactionAction,
    { isLoading: isVerifyingTransaction },
  ] = useVerifyTransactionMutation();

  const init = useCallback(({ onClose, onSuccess, ...args }: InitFlutterwaveArgs) => {
    const modal = window.FlutterwaveCheckout({
      public_key: process.env.GATSBY_FLUTTERWAVE_KEY,
      tx_ref: args.reference,
      amount: args.amount,
      currency: auth?.currencyCode,
      onclose() {
        onClose?.();
      },
      async callback(data) {
        modal.close();
        if (data.status === 'successful') {
          try {
            await verifyTransactionAction({ transactionId: data.transaction_id }).unwrap();
            onSuccess?.();
          } catch (error) {
            onClose?.();
            const exception = error as ErrorType;
            if (exception.status !== 500) {
              dispatch(addSnackbar({ message: getErrorMessage(exception), variant: 'error' }));
            }
          }
        }
      },
      customer: {
        email: auth?.email,
        name: auth?.fullName,
      },
    });
  }, [auth?.currencyCode, auth?.email, auth?.fullName, dispatch, verifyTransactionAction]);

  return [init, { isVerifying: isVerifyingTransaction }] as const;
};

export default useFlutterwave;
