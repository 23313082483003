import { useContext } from 'react';
import CurrencyChooserContext from './CurrencyChooserContext';

const useCurrencyChooser = () => {
  const context = useContext(CurrencyChooserContext);

  if (!context) {
    throw new Error('useCurrencyChooser hook must be used inside a CurrencyChooserContext');
  }

  return context;
};

export default useCurrencyChooser;
