import { Typography } from '@mui/material';
import { ReactNode, VFC } from 'react';

type SectionTitleProps = {
  children: ReactNode;
}

const SectionTitle: VFC<SectionTitleProps> = ({ children }) => (
  <Typography
    fontWeight="600"
    variant="h5"
    component="h1"
    gutterBottom
    marginTop={5}
  >
    {children}
  </Typography>
);

export default SectionTitle;
