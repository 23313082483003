import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { ServerResponse } from '../../../../src/types/app';
import { AddReviewFormData } from '../../../../src/types/form';
import { BookSchema, ReviewSchema } from '../../../../src/types/schema';
import { api } from './index';

const bookDetailsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBookDetails: builder.query<BookSchema, string>({
      keepUnusedDataFor: 60,
      query: (slug) => `b/${slug}`,
      transformResponse: (response: ServerResponse<BookSchema>) => response.data,
    }),
    toggleWishlist: builder.mutation<void, string>({
      query: (slug) => ({ url: `dashboard/wishlists/${slug}`, method: 'POST' }),
      onQueryStarted: async (slug, { dispatch, queryFulfilled }) => {
        const patches = dispatch(
          bookDetailsApi.util.updateQueryData('getBookDetails', slug, (draft) => {
            draft.wishlisted = !draft.wishlisted;
          }),
        );

        await queryFulfilled.catch(patches.undo);
      },
    }),
    getReviews: builder.query<ReviewSchema[], string>({
      query: (slug) => `b/${slug}/reviews`,
      transformResponse: (response: ServerResponse<ReviewSchema[]>) => response.data,
    }),
    addReview: builder.mutation<
      void,
      AddReviewFormData & { avatar: string; fullName: string; username: string }
    >({
      query: ({ slug, ...formData }) => ({ url: `dashboard/b/${slug}/reviews`, method: 'POST', body: formData }),
      onQueryStarted: async (
        { slug, rating, ...formData },
        { dispatch, queryFulfilled },
      ) => {
        const patches = dispatch(
          bookDetailsApi.util.updateQueryData('getReviews', slug, (draft) => {
            draft.unshift({
              ...formData,
              rating: rating || 0,
              id: uuid(),
              postedAt: dayjs().toISOString(),
            });
          }),
        );

        const bookPatches = dispatch(
          bookDetailsApi.util.updateQueryData('getBookDetails', slug, (draft) => {
            if (rating) {
              draft.hasReviewed = !draft.hasReviewed;
            }
          }),
        );

        await queryFulfilled.catch(() => {
          patches.undo();
          bookPatches.undo();
        });
      },
    }),
  }),
});

export const {
  useAddReviewMutation,
  useGetBookDetailsQuery,
  useGetReviewsQuery,
  useToggleWishlistMutation,
} = bookDetailsApi;
