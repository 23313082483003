import {
  BookmarkRemove, Cancel, CheckCircle, Share,
} from '@mui/icons-material';
import {
  Box, Button, CircularProgress, Grid,
  IconButton, Link, ListItem, ListItemAvatar,
  ListItemButton, ListItemText, Typography,
  Chip,
  Stack,
  Container,
} from '@mui/material';
import { navigate, PageProps } from 'gatsby';
import { useCallback, useEffect } from 'react';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import {
  useGetBookDetailsQuery,
  useToggleWishlistMutation,
} from '../../../plugins/gatsby-plugin-redux/store/api/book-details.api';
import BookCover from '../../components/app/BookCover';
import PricingText from '../../components/app/PricingText';
import Spacer from '../../components/app/Spacer';
import nl2br from '../../helpers/nl2br';
import FlutterwaveSDK from '../../components/app/FlutterwaveSDK';
import useAppDispatch from '../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { addSnackbar } from '../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import { ErrorType, getErrorMessage } from '../../helpers/handleSubmitAction';
import SEO from '../../components/app/SEO';
import Reviews from '../../components/pages/book-details/Reviews';
import SectionTitle from '../../components/pages/book-details/SectionTitle';
import Rating from '../../components/app/Rating';
import UserAvatar from '../../components/app/UserAvatar';
import Sharer from '../../components/app/Sharer';
import toCanonicalUrl from '../../helpers/toCanonicalUrl';
import useIsLoggedIn from '../../../plugins/gatsby-plugin-session-check/hooks/useIsLoggedIn';
import { toGtagItem, trackWishlist } from '../../helpers/gtag';
import useAuth from '../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import Checkout from '../../components/pages/book-details/Checkout';

const Slug = ({ params }: PageProps) => {
  const auth = useAuth();
  const isLoggedIn = useIsLoggedIn();
  const { data: book, isLoading } = useGetBookDetailsQuery(params.slug);

  const [toggleWishlistAction] = useToggleWishlistMutation();

  const dispatch = useAppDispatch();

  const toggleWishlist = useCallback(async () => {
    try {
      const hasWishlisted = book?.wishlisted;
      await toggleWishlistAction(book?.slug || '');
      if (hasWishlisted) {
        return dispatch(addSnackbar({
          message: 'Book removed from wishlist',
          variant: 'success',
        }));
      }
      if (book) {
        trackWishlist(
          toGtagItem([book], { currencyCode: auth?.currencyCode, quantity: 1 })[0],
          auth?.currencyCode,
        );
      }
      return dispatch(addSnackbar({
        message: 'Book added to wishlist',
        variant: 'success',
      }));
    } catch (error) {
      return dispatch(addSnackbar({
        message: getErrorMessage(error as ErrorType),
        variant: 'error',
      }));
    }
  }, [auth?.currencyCode, book, dispatch, toggleWishlistAction]);

  useEffect(() => {
    if (!isLoading && !book) {
      void navigate('/404');
    }
  }, [book, isLoading]);

  if (isLoading || !book) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <FlutterwaveSDK />
      <SEO title={book.title} />

      <Box marginTop={5}>
        <Grid container justifyContent="center" columnSpacing={2}>
          <Grid item xs="auto" md="auto" marginBottom={{ xs: 3, md: 0 }}>
            <BookCover
              src={book.cover}
              width={200}
              height={317.45}
            />
          </Grid>
          <Grid item xs={12} md display="flex" flexDirection="column">
            <Box marginBottom={1}>
              <Typography variant="h3" component="h1" fontWeight="600">{book.title}</Typography>
              <Chip label={book.type} size="small" />
            </Box>
            <Stack flexDirection="row" columnGap={1}>
              <Typography fontWeight={600}>
                ISBN:
              </Typography>
              <Typography>
                {book.ISBN}
              </Typography>
            </Stack>
            {book.publishedDate && (
            <Stack flexDirection="row" columnGap={1}>
              <Typography fontWeight={600}>
                First Published:
              </Typography>
              <Typography>
                {book.publishedDate}
              </Typography>
            </Stack>
            )}
            {book.language.name && (
            <Stack flexDirection="row" columnGap={1}>
              <Typography fontWeight={600}>
                Language:
              </Typography>
              <Typography>
                {book.language.name}
              </Typography>
            </Stack>
            )}
            <Stack flexDirection="row" columnGap={1}>
              <Typography fontWeight={600}>
                Rating:
              </Typography>
              <Rating value={book.rating} readOnly />
              {' '}
              <Typography component="span">
                {`(${book.rating})`}
              </Typography>
            </Stack>
            <Stack flexDirection="row" columnGap={1}>
              <Typography gutterBottom fontWeight={600}>
                Pricing:
              </Typography>
              <PricingText items={book.pricings} pricingType={book.pricingType} />
            </Stack>
            {book.type === 'ebook' && (
              <Box
                marginTop={1}
                display="flex"
                alignItems="center"
                columnGap={1}
                color={(theme) => ((book.isOutOfStock && !book.releaseDate)
                  ? theme.palette.error.main
                  : theme.palette.success.main)}
              >
                {book.isOutOfStock ? <Cancel /> : <CheckCircle />}
                {book.isOutOfStock ? 'Out of stock' : 'Available'}
              </Box>
            )}
            <Spacer />
            <Grid
              container
              columnSpacing={3}
              marginTop={{ xs: 3, md: 0 }}
              rowSpacing={{ xs: 1, md: 0 }}
            >
              {book.type === 'ebook' && (
              <Grid item xs={12} md="auto">
                <Checkout book={book} slug={params.slug} />
              </Grid>
              )}
              {isLoggedIn && (
              <Grid item xs={12} md="auto">
                <Button
                  sx={{ display: { md: 'none' } }}
                  variant="outlined"
                  startIcon={book?.wishlisted ? <BookmarkRemove /> : <BookmarkAddIcon />}
                  fullWidth
                  onClick={toggleWishlist}
                >
                  {book?.wishlisted ? 'Remove from Wishlist' : ' Add to Wishlist'}
                </Button>
                <IconButton
                  sx={{ display: { xs: 'none', md: 'unset' } }}
                  title={book?.wishlisted ? 'Remove from wishlist' : 'Add to wishlist'}
                  onClick={toggleWishlist}
                >
                  { book?.wishlisted ? <BookmarkRemove /> : <BookmarkAddIcon />}
                </IconButton>
              </Grid>
              )}
              <Grid item xs={12} md="auto">
                <Sharer
                  url={toCanonicalUrl(`/b/${book.slug}`)}
                  title={`Read ${book.title} and other interesting African books`}
                >
                  {(config) => (
                    <>
                      <Button
                        sx={{ display: { md: 'none' } }}
                        onClick={config.onOpen}
                        variant="outlined"
                        startIcon={<Share />}
                        fullWidth
                      >
                        Share
                      </Button>
                      <IconButton
                        onClick={config.onOpen}
                        sx={{ display: { xs: 'none', md: 'unset' } }}
                      >
                        <Share />
                      </IconButton>
                    </>
                  )}
                </Sharer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={5}>
        <Box component="section">
          <SectionTitle>Blurb</SectionTitle>
          <Typography>
            <span dangerouslySetInnerHTML={{ __html: nl2br(book.description) }} />
          </Typography>
        </Box>

        <Box component="section">
          <SectionTitle>Contributors</SectionTitle>
          <Grid container>
            {book.collaborators.map((collaborator) => (
              <Grid item key={`${collaborator.name}-${collaborator.type}`}>
                <ListItem disablePadding>
                  <ListItemButton href={`/${collaborator.username}`} component={Link}>
                    <ListItemAvatar>
                      <UserAvatar
                        src={collaborator.avatar}
                        name={collaborator.name}
                        showDefault={false}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={collaborator.name} secondary={collaborator.type} />
                  </ListItemButton>
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box component="section">
          <SectionTitle>Genres</SectionTitle>
          <Grid container gap={2}>
            {book.genres.map((item) => (
              <Grid item key={item.slug} xs="auto">
                <Chip
                  component={Link}
                  href={`/search/${item.slug}/?type=${book.type}`}
                  label={item.title}
                  sx={{ cursor: 'pointer' }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Reviews slug={params.slug} book={book} />
      </Box>
    </Container>
  );
};

export default Slug;
