import {
  Button,
  DialogActions,
  DialogContent,
} from '@mui/material';
import searchInsights from 'search-insights';
import { useLocation } from '@reach/router';
import {
  useCallback, useMemo, useState, VFC,
} from 'react';
import useAppDispatch from '../../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import {
  usePayWalletMutation,
} from '../../../../plugins/gatsby-plugin-redux/store/api/checkout.api';
import { addSnackbar } from '../../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import { ErrorType, getErrorMessage } from '../../../helpers/handleSubmitAction';
import useAuth from '../../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import FlutterwaveSDK from '../../app/FlutterwaveSDK';
import FundWalletFlow from '../profile/FundWalletFlow';
import Dialog from '../../app/Dialog';
import DialogTitle from '../../app/DialogTitle';
import { toGtagItem, trackBeginCheckout, trackPurchase } from '../../../helpers/gtag';
import ThankYouDialog from '../Checkout/ThankYouDialog';
import { BookSchema } from '../../../types/schema';
import { indexName } from '../../../helpers/config';
import bigNumber from '../../../helpers/bigNumber';
import LoadingButton from '../../app/LoadingButton';
import AlertDialog from '../../app/AlertDialog';

type CheckoutProps = {
  book: BookSchema;
  slug: string;
}

const Checkout: VFC<CheckoutProps> = ({ book, slug }) => {
  const auth = useAuth();
  const location = useLocation();
  const queryID = new URLSearchParams(location.search).get('queryID') as string;
  const dispatch = useAppDispatch();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showInsufficientBalanceModal, setShowInsufficientModal] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [showFundWalletFlow, setShowFundWalletFlow] = useState(false);

  const [payWithWalletAction, { isLoading: isPayingWithWallet }] = usePayWalletMutation();

  const quantity = 1;
  const bookPrice = useMemo(() => (
    book?.pricings?.find((item) => item.currencyCode === auth?.currencyCode)
  ), [book?.pricings, auth?.currencyCode]);

  const total = useMemo(() => (
    bigNumber(bookPrice?.amount)), [bookPrice?.amount]);
  const userBalance = useMemo(() => bigNumber(auth?.balance).dividedBy(100), [auth?.balance]);

  const isPaid = useMemo(() => book?.pricingType === 'paid' && !!bookPrice?.amount, [book?.pricingType, bookPrice?.amount]);

  const hasFund = book?.pricings?.length
    ? userBalance.isGreaterThanOrEqualTo(total)
    : false;

  const canBuy = useMemo(() => (isPaid ? hasFund : true), [hasFund, isPaid]);

  const handlePayWithWallet = useCallback(async () => {
    try {
      trackBeginCheckout(toGtagItem(
        [book],
        {
          currencyCode: auth?.currencyCode, listName: 'Checkout', quantity,
        },
      )[0], auth?.currencyCode);

      const result = await payWithWalletAction({ slug }).unwrap();
      setShowThankYouModal(true);
      trackPurchase(toGtagItem([book], {
        currencyCode: auth?.currencyCode,
        listName: 'Checkout',
        quantity: 1,
      })[0], {
        currencyCode: auth?.currencyCode,
        reference: result.reference,
      });
    } catch (error) {
      const exception = error as ErrorType;
      if (exception.status !== 500) {
        dispatch(addSnackbar({ message: getErrorMessage(error as ErrorType), variant: 'error' }));
      }
    }
  }, [payWithWalletAction, slug, book, auth?.currencyCode, dispatch]);

  const handlePayment = useCallback(() => {
    if (!canBuy) {
      return setShowInsufficientModal(true);
    }

    searchInsights(
      queryID ? 'convertedObjectIDsAfterSearch' : 'convertedObjectIDs',
      {
        index: indexName,
        eventName: queryID
          ? 'PDP: Book Added to Cart after Search'
          : 'PDP: Book Added to Cart',
        objectIDs: [`App\\Models\\Book::${book?.id}`],
        queryID,
      },
    );

    return handlePayWithWallet();
  }, [book?.id, canBuy, handlePayWithWallet, queryID]);

  const handleFundWalletComplete = useCallback(async () => {
    await handlePayWithWallet();
  }, [handlePayWithWallet]);

  const handleSubmit = useCallback(async () => {
    setShowWarningModal(false);
    await handlePayment();
  }, [handlePayment]);

  return (
    <>
      <FlutterwaveSDK />

      <FundWalletFlow
        isOpen={showFundWalletFlow}
        onComplete={handleFundWalletComplete}
        onClose={() => setShowFundWalletFlow(false)}
        defaultAmount={total.isGreaterThan(userBalance)
          ? +total.minus(userBalance).toFixed(2)
          : undefined}
      />

      <AlertDialog
        content={isPaid
          ? `You are about to purchase ${book.title}. Your wallet will be charged for this transaction. Click Proceed to continue.`
          : `You are about to add ${book.title} to your library. Click Proceed to continue.`}
        btnOk={{ label: 'Proceed', onSubmit: handleSubmit, isLoading: isPayingWithWallet }}
        isOpen={showWarningModal}
        onClose={() => setShowWarningModal(false)}
      />

      <Dialog
        open={showInsufficientBalanceModal}
        onClose={() => setShowInsufficientModal(false)}
      >
        <DialogTitle>Insufficient balance</DialogTitle>
        <DialogContent>
          We are currently unable to process this payment due
          to insufficient balance. Click on &quot;Fund Wallet&quot; to add money to your wallet.
        </DialogContent>
        <DialogActions>
          <Button
            hidden={hasFund}
            variant="text"
            disableElevation
            onClick={() => {
              setShowInsufficientModal(false);
              setShowFundWalletFlow(true);
            }}
          >
            Fund Wallet
          </Button>
        </DialogActions>
      </Dialog>

      <ThankYouDialog
        isOpen={showThankYouModal}
        onClose={() => setShowThankYouModal(false)}
        book={book}
      />

      <LoadingButton
        variant="contained"
        fullWidth
        disabled={book.isOutOfStock}
        onClick={() => setShowWarningModal(true)}
        loading={isPayingWithWallet}
      >
        Add to library
      </LoadingButton>
    </>
  );
};

export default Checkout;
