import { CheckCircle } from '@mui/icons-material';
import { Typography, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { navigate } from 'gatsby';
import { VFC } from 'react';
import { BookSchema } from '../../../types/schema';
import Button from '../../app/Button';
import Dialog from '../../app/Dialog';

type ThankYouDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  book: BookSchema;
}

const ThankYouDialog: VFC<ThankYouDialogProps> = ({
  isOpen,
  onClose,
  book,
}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <Box paddingX={5} paddingY={5} textAlign="center">
      <CheckCircle sx={{ fontSize: '3rem', marginBottom: 2 }} color="primary" />
      <Typography variant="h5" marginBottom={3} fontWeight="bold">Thank you for your order!</Typography>
      <Typography>
        {`Woohoo! ${book?.title} has been added to your library. You can read it now or read it later in your Library.`}
      </Typography>

      <Stack marginTop={5}>
        <Button
          sx={{ width: '70%', marginX: 'auto', marginBottom: 1 }}
          onClick={() => navigate(`/app/reader/${book.slug}`)}
        >
          Read it now
        </Button>
        <Button
          sx={{ width: '70%', margin: 'auto' }}
          variant="outlined"
          onClick={onClose}
        >
          Read it later
        </Button>
      </Stack>
    </Box>
  </Dialog>

);

export default ThankYouDialog;
