import {
  DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle,
} from '@mui/material';
import {
  MouseEventHandler, ReactElement, VFC,
} from 'react';
import Button from './Button';
import Dialog from './Dialog';
import LoadingButton from './LoadingButton';

type AlertDialogProps = {
  persistent?: boolean;
  isOpen: boolean;
  onClose: DialogProps['onClose'];
  title?: string | ReactElement;
  content: string | ReactElement | null;
  btnClose?: { label?: string; onClose?: MouseEventHandler<HTMLButtonElement>; };
  btnOk?: {
    label?: string;
    onSubmit?: MouseEventHandler<HTMLButtonElement>
     | (() => Promise<MouseEventHandler<HTMLButtonElement>>);
    isLoading?: boolean;
  };
}

const AlertDialog: VFC<AlertDialogProps> = ({
  content,
  isOpen,
  onClose,
  title,
  btnClose,
  btnOk,
  persistent,
}) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    persistent={persistent}
  >
    {title && (
    <DialogTitle sx={{ fontWeight: '600' }}>
      {title}
    </DialogTitle>
    )}
    <DialogContent>
      <DialogContentText>
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {btnClose?.label && <Button variant="text" onClick={btnClose?.onClose}>{btnClose?.label}</Button>}
      {btnOk?.label && (
      <LoadingButton variant="text" loading={btnOk?.isLoading} onClick={btnOk?.onSubmit} autoFocus>
        {btnOk?.label}
      </LoadingButton>
      )}
    </DialogActions>
  </Dialog>
);

export default AlertDialog;
